import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncSearchUser({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/searchUser"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncSearchUser;
