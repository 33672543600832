import {
  isAgencySuperAdminRole,
  isSuperAdminRole,
  isSystemAdminRole,
  isSystemFBPosterRole,
} from "../../roles/service/roles";

export async function showAdminMenu(user) {
  if (
    (await isSystemAdminRole(user)) ||
    (await isSuperAdminRole(user)) ||
    (await isSystemFBPosterRole(user)) ||
    (await isAgencySuperAdminRole(user))
  ) {
    return true;
  }

  return false;
}
