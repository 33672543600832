import React from "react";
import YoutubeEmbed from "../../common/youtubeEmbed";
import { Col, Row } from "react-bootstrap";

function Videos() {
  return (
    <Row>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="rocket">
            🚀
          </span>{" "}
          How to Download FREE CV?
        </p>
        <YoutubeEmbed youtubeid="qufEkqYixWE" />
      </Col>
      {/* <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="construct">
            👷🏻‍♂️
          </span>{" "}
          How to Create FREE CV?
        </p>
        <YoutubeEmbed youtubeid="KRGYhyDx79U" />
      </Col> */}
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="celebrate">
            🎉
          </span>{" "}
          How to Apply Jobs
        </p>
        <YoutubeEmbed youtubeid="OzmwwGDz8zc" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="celebrate">
            🎉
          </span>{" "}
          How to Upload Existing CV
        </p>
        <YoutubeEmbed youtubeid="eqADZSaZnyQ" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="celebrate">
            🎉
          </span>{" "}
          How to Create FREE Cover Letter
        </p>
        <YoutubeEmbed youtubeid="8OXizAYxs0E" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="fire">
            🔥
          </span>{" "}
          Use AI for Career Objective
        </p>
        <YoutubeEmbed youtubeid="pn93ib5wfxQ" />
      </Col>
    </Row>
  );
}

export default Videos;
