import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ShareOrCopySeamanBio({ link, text, title, item }) {
  const url = link || "https://seamanbio.ph";
  const [showcopymodal, setShowCopyModal] = useState(false);

  const shareDetails = { url, title, text };

  const handleClose = () => {
    setShowCopyModal(false);
  };
  const handleCopy = () => {
    // const logname = `link_copy_${source}`;
    // logEvent(logname, {
    //   text: `link copy ${source}`,
    // });

    setShowCopyModal(true);
  };

  if (!navigator.share) {
    //show copy only
    return (
      <div>
        <CopyToClipboard text={url} onCopy={() => handleCopy()}>
          <Button
            variant={`text-dark`}
            className="float-right btn-sm py-2 pl-0 pr-0">
            <div className="float-left" style={{ width: "25px" }}>
              <ShareIcon
                className="text-primary"
                style={{
                  fontSize: "18px",
                  marginTop: "-2px",
                }}
              />
            </div>
            <div className="float-left">Share</div>
          </Button>
        </CopyToClipboard>
        <Modal
          show={showcopymodal}
          onHide={handleClose}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Link Copied
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-0 mb-4 text-center">
            <CheckCircleIcon
              className="text-success"
              style={{ fontSize: "50px" }}
            />
            <p className="mt-2 font-weight-bold text-primary">
              <a target="_blank" rel="noopener noreferrer" href={url}>
                {url}
              </a>
            </p>
            <p className="mt-2 text-dark">
              Share with your friends using the link you copied{" "}
              <span role="img" aria-label="rocker">
                🚀
              </span>
            </p>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="link" className="mr-2" onClick={handleClose}>
              <span className="">Got it</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <Button
      variant="link"
      className="float-right btn-sm text-dark px-3 py-2"
      onClick={handleSharing}>
      <div className={`float-left `} style={{ width: "25px" }}>
        <ShareIcon
          className="text-primary"
          style={{
            fontSize: "18px",
            marginTop: "-2px",
          }}
        />
      </div>{" "}
      Share
      {/* <div className={`float-left defaultstandardfontsize`}>Share</div> */}
    </Button>
  );
}

export default ShareOrCopySeamanBio;
