import React from "react";
import Header from "../../common/header";
import FacebookPostsFetcher from "../component/facebookPostsFetcher";

function FbInfo({ user }) {
  return (
    <div>
      <Header type="backandlogo" hide="profile" hidehome hidecopy />

      <div className="row justify-content-center mx-auto appearslowly">
        <div className="col-lg-8 text-center mx-0 px-0">
          <main className="container text-center mx-auto ">
            <FacebookPostsFetcher />
          </main>
        </div>
      </div>
    </div>
  );
}

export default FbInfo;
