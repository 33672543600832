import React, { useState, useEffect } from "react";

const FacebookPostsFetcher = () => {
  const [pageUrl, setPageUrl] = useState("");
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Load the Facebook SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3916070005327570", // Replace with your Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v21.0",
      });
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted URL:", pageUrl);

    // Extract page name or ID from URL
    const pageIdentifier = pageUrl.split("/").filter(Boolean).pop();
    console.log("Page Identifier:", pageIdentifier);

    fetchPagePosts(pageIdentifier);
  };

  const fetchPagePosts = (pageIdentifier) => {
    window.FB.api(
      `/${pageIdentifier}/posts`,
      "GET",
      { fields: "message,created_time,id", limit: "3" },
      function (response) {
        if (response && !response.error) {
          console.log("Fetched posts:", response.data);
          setPosts(response.data);
          setError(null);
        } else {
          console.error("Error fetching posts:", response.error);
          setError("Error fetching posts. Please check the URL and try again.");
          setPosts([]);
        }
      }
    );
  };

  const testFBConnection = () => {
    if (window.FB) {
      window.FB.api("/me", function (response) {
        console.log("FB API response:", response);
      });
    } else {
      console.log("FB SDK not loaded yet");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={pageUrl}
          onChange={(e) => setPageUrl(e.target.value)}
          placeholder="Enter Facebook Page URL"
        />
        <button type="submit">Fetch Posts</button>
      </form>
      <button onClick={testFBConnection}>Test FB Connection</button>

      {error && <p style={{ color: "red" }}>{error}</p>}

      <div>
        {posts.map((post) => (
          <div
            key={post.id}
            style={{
              marginBottom: "20px",
              border: "1px solid #ccc",
              padding: "10px",
            }}>
            <p>
              <strong>Post ID:</strong> {post.id}
            </p>
            <p>
              <strong>Message:</strong> {post.message || "No message"}
            </p>
            <p>
              <strong>Created Time:</strong>{" "}
              {new Date(post.created_time).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacebookPostsFetcher;
