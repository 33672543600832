import { getDaySinceStartDate } from "./date";

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function shuffleArray(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export function rotateDailyArray(agencyListNonExpired) {
  const dayOfYear = getDaySinceStartDate();
  const rotationCount = dayOfYear % agencyListNonExpired.length;

  const rotatedArray = agencyListNonExpired
    .slice(rotationCount)
    .concat(agencyListNonExpired.slice(0, rotationCount));

  return rotatedArray;
}
