import React from "react";

const Select = ({
  name,
  label,
  labelextra = null,
  options,
  error,
  init = "",
  css = "form-control-lg custom-select-lg",
  ...rest
}) => {
  const initValue = init?.trim();
  let formClass = `form-control ${css} custom-select  `;
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label}
        {labelextra}
      </label>
      <select name={name} id={name} {...rest} className={formClass}>
        {initValue?.length === 0 ? (
          <option value="" />
        ) : (
          <option value={initValue}>{initValue}</option>
        )}
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Select;
