import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/welcome.css";
import { Button, Col, Row } from "react-bootstrap";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
// import Typist from "react-typist";
import Footer from "../../common/footer";
import "react-typist/dist/Typist.css";
import {
  getWebsiteFullDescription,
  getWebsiteName,
  getWebsitePrefix,
  getWebsiteSuffix,
} from "../../../services/settings";
import { getLatestVersion } from "../../../services/getversion";
import YoutubeEmbed from "../../common/youtubeEmbed";
import ShareSeamanBio from "../../share/shareSeamanBio";
import ReloadModal from "../../common/reloadModal";
import Testimonials from "../../testimonials/component/testimonial";
import FeatureImage from "../component/featureImage";
import { getSiteSettingsDB } from "../../../services/config";
import StatCounter from "../../common/statCounter";
import ScrollToTop from "../../common/scrollToTop";
import SpreadWord from "../component/spreadWord";
// import OpenInChrome from "../../common/openInChrome";
// import { isInAppBrowser } from "../../../utils/browser";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tagline from "../../common/tagline";
import Videos from "../../tutorial/component/videos";
// import TargetUsers from "../../common/targetUsers";

const WelcomePage2 = ({ ...props }) => {
  const [settings, setSettings] = useState({
    members: "12,980",
    jobs: 6010,
    agencies: 350,
    applications: "155,133",
  });
  const [version, setVersion] = useState(null);
  const [showcopymodal, setShowCopyModal] = useState(false);
  const [text, setText] = useState("");
  const textStyle = "text-dark";
  const texttocopy = "https://seamanbio.ph";

  useEffect(() => {
    const getDatas = async () => {
      getLatestVersion().then((version) => {
        setVersion(version);
      });
      getSiteSettingsDB().then((setting) => {
        setSettings(setting);
      });
    };
    getDatas();
  }, []);

  const handleClose = () => {
    setShowCopyModal(false);
  };

  const handleCopy = () => {
    // const logname = `link_copy_${source}`;
    // logEvent(logname, {
    //   text: `link copy ${source}`,
    // });

    setShowCopyModal(true);
    setText("You may now share www.SeamanBio.ph with your friends 🚀");
  };
  const params = props.match.params;
  let refid = null;
  if (params) {
    refid = params?.refid || "";
  }
  let el = document.querySelector("title");
  const title = `${getWebsiteName()}`;
  const content = getWebsiteFullDescription();
  el.innerText = title;
  el = document.querySelector("meta[name='description']");
  el.setAttribute("content", content);
  return (
    <div style={{ overflowX: "hidden" }}>
      {version && <ReloadModal version={version} />}

      <div className="container col-lg mt-0 pt-0">
        <div className="row align-items-center d-flex pt-4 mt-4">
          <div className="text-center container h-50 pb-0 mb-0">
            <DirectionsBoatIcon className="logoborder logo-big text-white p-2" />
            <h1 className="mb-0 pb-0 font-weight-bold text-primary pointer">
              {getWebsitePrefix()}
              <span className="text-muted font-weight-normal">
                {getWebsiteSuffix()}
              </span>
            </h1>
            {/* <p className="text-muted standardfontsize">
              Alpha v{getWebVersion()}
            </p> */}
            <div className="text-center font-weight-bold defaultfontsize">
              <Tagline />
              {/* <span role="img" aria-label="star"> */}
              {/* ⭐⭐⭐⭐⭐ */}
              {/* </span> */}
              {/* <TargetUsers /> */}
            </div>
            <div className="mt-3 mb-0">
              <span className="font-weight-bold text-danger">
                <i>{settings?.agencies} agencies</i>
              </span>{" "}
              waiting for you...
            </div>
            {/* <div className="text-center mt-2">
              <Typist cursor={{ show: true }}>
                <span>Your CV is your first impression.</span>
                <Typist.Backspace count={40} delay={2000} />
                <span>A complete CV makes you stand out.</span>
                <Typist.Backspace count={40} delay={1800} /> */}
            {/* <span>You get noticed better with complete CV.</span>
                <Typist.Backspace count={40} delay={1800} />
                <span>Your complete CV increases your chances.</span>
                <Typist.Backspace count={40} delay={1800} />
                <span>Your completed CV opens more doors.</span>
                <Typist.Backspace count={40} delay={1800} />
                <span>Completing your CV boosts your chances.</span> */}
            {/* <Typist.Backspace count={40} delay={1800} /> */}
            {/* <span>Get noticed, upgrade your CV now.</span> */}
            {/* </Typist> */}
            {/* </div> */}
            {/* <p className="defaultfontsize pb-0 mb-0 text-black">
              {getWebsiteDescription()}
            </p> */}
            {/* <div className="">
              <div className="justify-content-center flex-column align-items-center d-flex mt-0 mb-4"> */}
            {/* <div className="d-flex justify-content-start">
                  <FormatQuoteRoundedIcon
                    className="text-success"
                    style={{
                      opacity: "0.9",
                      transform: "scaleX(-1)", // flip horizontally
                      fontSize: "40px",
                      marginLeft: "-10px",
                    }}
                  />
                </div> */}

            {/* <div className="d-flex justify-content-end">
                  <FormatQuoteRoundedIcon
                    className="text-muted"
                    style={{
                      opacity: "0.6",
                      transform: "scaleX(1)", // flip horizontally
                      fontSize: "40px",
                    }}
                  />
                </div> */}
            {/* <div className="text-center">
                  <Typist>A must-have tool for All Seaman...</Typist>
                </div> */}
            {/* </div>
            </div> */}

            <div style={{ width: "280px" }} className="mx-auto text-center">
              <div className="pt-4">
                <p className="font-weight-bolds text-center text-muted mb-2 mt-0">
                  No account?
                </p>
                <Link
                  className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
                  to={`/register/${refid}`}>
                  Create FREE CV
                </Link>
              </div>
              <div className="mt-4 mb-3">
                <p className="font-weight-bolds text-center text-muted mb-2 mt-0">
                  Already have an account?
                </p>
                <Link
                  style={{ border: "2px dashed #007bff" }}
                  className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                  to={`/login/${refid}`}>
                  Log In
                </Link>
              </div>
              <div className="mt-4 mb-0">
                <Link
                  className="text-primary normalsmallfontsize btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                  to={`/forgot/${refid}`}>
                  <u>Forgot your password?</u>
                </Link>
              </div>
            </div>
            {/* {isInAppBrowser() && (
              <div className="pt-3">
                <OpenInChrome />
              </div>
            )} */}
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="pt-5">
          <div className="pb-4 mb-0 bg-white">
            <hr style={{ borderTop: "6px dashed #cde6fe" }} />
          </div>
        </div>
        <div className="bg-white">
          {settings && (
            <div className="pt-2 pb-2">
              <StatCounter settings={settings} />
              <div className="text-center pt-4 pb-4 mb-0 mx-auto px-4 col-lg-6">
                <hr style={{ borderTop: "5px dashed #cde6fe" }} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-center bg-white">
        <div className="container col-lg-12 bg-white pb-4">
          <Testimonials
            settings={{ 270: 1, 350: 1, 750: 1, 800: 1, 900: 2 }}
            showFeedback={false}
          />
          <div style={{ width: "280px" }} className="mx-auto text-center">
            <div className="pt-4">
              <Link
                className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
                to={`/register/${refid}`}>
                Join Now
              </Link>
            </div>
            <div className="mt-4 mb-3">
              <Link
                style={{ border: "2px dashed #007bff" }}
                className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                to={`/login/${refid}`}>
                Log In
              </Link>
            </div>
          </div>
        </div>
        <hr style={{ borderTop: "5px dashed #cde6fe" }} />
      </div>
      <div className="text-center pt-5">
        <div className="mx-auto text-left container col-lg-8">
          <FeatureImage />
          <div style={{ width: "280px" }} className="mx-auto text-center">
            <div className="pt-4">
              <Link
                className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
                to={`/register/${refid}`}>
                Join Now
              </Link>
            </div>
            <div className="mt-4 mb-3">
              <Link
                style={{ border: "2px dashed #007bff" }}
                className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                to={`/login/${refid}`}>
                Log In
              </Link>
            </div>
          </div>
          <div className="mt-4 pt-4 pb-4 mb-4">
            <hr style={{ borderTop: "5px dashed #cde6fe" }} />
          </div>
        </div>
        <div className="container col-lg-8 mt-0 pt-0">
          <div className="row align-items-center d-flex pt-0 mt-0">
            <div className="text-center container h-50 pb-0 mb-0">
              <div>
                {/* 🌎 Help Spread the Word */}
                <SpreadWord />
                <div className="py-4 text-center d-flex justify-content-around alert alert-danger  roundcornernocolor">
                  <ShareSeamanBio
                    title={"Share SeamanBio.ph"}
                    text={"https://seamanbio.ph"}
                    item={null}
                  />
                  <CopyToClipboard
                    text={"https://seamanbio.ph"}
                    onCopy={() => handleCopy()}>
                    <Button
                      variant={`text-dark`}
                      className="float-right btn-sm py-2 pl-2 pr-3">
                      <div className="float-left" style={{ width: "25px" }}>
                        <ShareIcon
                          className="text-primary"
                          style={{
                            fontSize: "18px",
                            marginTop: "-2px",
                          }}
                        />
                      </div>
                      <div className="float-left">Share</div>
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto text-left container col-lg-8">
        <div className="pt-4 pb-2">
          <hr style={{ borderTop: "5px dashed #cde6fe" }} />
        </div>
        <Videos />
        <div style={{ width: "280px" }} className="mx-auto text-center">
          <div className="pt-4">
            <Link
              className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
              to={`/register/${refid}`}>
              Join Now
            </Link>
          </div>
          <div className="mt-4 mb-3">
            <Link
              style={{ border: "2px dashed #007bff" }}
              className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
              to={`/login/${refid}`}>
              Log In
            </Link>
          </div>
        </div>
        <div className="my-4 pt-4 pb-2">
          <hr style={{ borderTop: "5px dashed #cde6fe" }} />
        </div>
      </div>

      <div className="container col-lg-6">
        <div className="container">
          <div className="my-4 pb-2">
            <p className="font-weight-bold averagefontsize pt-0 pb-2 text-left">
              Please help us spread SeamanBio.PH{" "}
              <span role="img" aria-label="point">
                👇
              </span>
            </p>

            <div className="py-4 text-center d-flex justify-content-around alert alert-danger  roundcornernocolor">
              <ShareSeamanBio
                title={"Share SeamanBio.ph"}
                text={"https://seamanbio.ph"}
                item={null}
              />
              <CopyToClipboard text={texttocopy} onCopy={() => handleCopy()}>
                <Button
                  variant={`${textStyle}`}
                  className="float-right btn-sm py-2 pl-2 pr-3">
                  <div className="float-left" style={{ width: "25px" }}>
                    <ShareIcon
                      className="text-primary"
                      style={{
                        fontSize: "18px",
                        marginTop: "-2px",
                      }}
                    />
                  </div>
                  <div className="float-left">Share</div>
                </Button>
              </CopyToClipboard>
            </div>
            <div className="text-center">
              <ScrollToTop />
            </div>

            <Footer />
            <Modal
              show={showcopymodal}
              onHide={handleClose}
              animation={false}
              centered
              backdrop="static"
              keyboard={true}>
              <Modal.Header style={{ border: "none" }}>
                <Modal.Title className="text-dark font-weight-bold">
                  Link Copied
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mt-0 mb-4 text-center">
                <CheckCircleIcon
                  className="text-success"
                  style={{ fontSize: "50px" }}
                />
                <br />
                <br />
                {text}
              </Modal.Body>
              <Modal.Footer style={{ border: "none" }}>
                <Button
                  variant="link"
                  className="forcenoline noline mr-2"
                  onClick={handleClose}>
                  <span className="text-muted forcenoline noline">Got it</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage2;
