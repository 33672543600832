export function convertFireStorageUrl(url) {
  const convertUrl = false;
  let validUrl = url;
  if (convertUrl) {
    // const decoded = decodeURIComponent(url);
    // console.log(decoded);
    const bucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
    const invalidPrefix = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/`;
    const validPrefix = `https://storage.googleapis.com/${bucket}`;
    const replaced = url.replace(invalidPrefix, "");
    validUrl = `${validPrefix}/${replaced}`;
  }
  return validUrl;
}

export function getSSSImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FSSS_90x90.jpeg?alt=media&token=72827775-9ab1-4e8d-9896-f759717772f9";
}

export function getPhilHealthImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FPhilHealth_90x90.jpeg?alt=media&token=258096f8-b40d-4d11-97ba-9324621e4582";
}

export function getPagIBIGImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FPagIBIG_90x90.jpeg?alt=media&token=ecb2550a-2aaf-4c5c-8841-c91406310adb";
}

export function getTINImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FBIR_90x90.jpeg?alt=media&token=8804b16e-5d5f-4b1a-98c3-3cc38956087a";
}

export function getPOEAImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FPOEA_90x90.jpeg?alt=media&token=69bb896a-e9ff-45d7-93bd-9753b618064e";
}

export function getDMWImage() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fphlogos%2Fthumbs%2FDMW_90x90.jpeg?alt=media&token=49eac645-8fd2-438f-ab4f-9a3311bc7eee";
}

export function getUSCircleFlag() {
  return "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Fimages%2Fflags%2Fusa-flag-round-circle-icon.png?alt=media&token=8dfb5212-1997-42d8-b0ed-6c97d5bddda0";
}
